// Step 1: Import React
import * as React from 'react'
import Layout from '../components/layout'
import { container } from '../components/layout.module.css'
import { StaticImage } from "gatsby-plugin-image"

// Step 2: Define your component
const incorrectPage = () => {
  return (
    <div className={container}>
      <main>
        <Layout>

          <StaticImage src="../../static/404.jpg" alt="Get Into Investing!" />

          <table align="center">
            <tr>
              <td width="650px">
              <div><br />


                  </div>
              </td>  
            </tr>
          </table>

        </Layout>
      </main>
    </div>
  )
}

// Step 3: Export your component
export default incorrectPage